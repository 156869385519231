import React from "react"
import Layout from "../../components/Layout"
import ContactUsComponent from "../../components/ContactUs"
import SEO from "../../components/seo"

export default function ContactUs() {
  return (
    <Layout headerType="business">
      <SEO title="Compulease · Contact Us Business" />
      <ContactUsComponent sector="Business" />
    </Layout>
  )
}
